let db = null;
const storeName = "myStore";

const indexedDBFunctions = {
  initializeDatabase() {
    return new Promise((resolve, reject) => {
      const dbName = "olp";
      const dbVersion = 1; // Increment for schema updates
      const request = indexedDB.open(dbName, dbVersion);

      request.onupgradeneeded = (event) => {
        db = event.target.result;

        // Create object store if it doesn't already exist
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: "bookId", autoIncrement: true });
        }
      };

      request.onsuccess = (event) => {
        db = event.target.result;
        resolve(db);
      };

      request.onerror = (event) => {
        console.error("Database error:", event.target.error);
        reject(event.target.error);
      };
    });
  },
  getDatabaseInstance() {
    if (!db) {
      throw new Error("Database is not initialized. Call initializeDatabase first.");
    }
    return db;
  },
  async getBookFromStore(bookId) {
    const db = this.getDatabaseInstance();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.get(bookId);

      request.onsuccess = (event) => {
        resolve({isCached: event.target.result != null, data: event.target.result}); // Resolves with book data if it exists
      };

      request.onerror = (event) => {
        reject({isCached: false, data: event.target.error});
      };
    });
  },
  async saveBookToStore(book) {
    const db = this.getDatabaseInstance();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], "readwrite");
      const store = transaction.objectStore(storeName);
      const request = store.put(book); // Save or update the book

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }
}

export default indexedDBFunctions;