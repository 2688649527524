import Repository from './Repository';

export default {
    getBooks(userId) {
        var response = Repository.get('Reader/GetUserBooks', {
			params: {
				UserId: userId
			}
		});
		return response;
	},

	async getCoverImage(bookId, userId) {
		var response = await Repository.get('Reader/GetCoverImage', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {	
				bookId: bookId,
				userId: userId
			}
		});
		
		if (response.headers && response.headers['content-type'].includes('application/json')) {
			const decoder = new TextDecoder()
			return {success: response.success, data: JSON.parse(decoder.decode(response.data))}
		}
		return response;
	}
}