import Repository from './Repository';

export default {
  getFoundationPhaseAssessmentStructure(periodId, qualificationId, subjectId) {
    return Repository.get('StructuredAssessment/GetAdminStructure', {
        params: {
            PeriodId: periodId,
            QualificationId: qualificationId,
            SubjectId: subjectId
        }
    });
  },

  copyAssessmentStructure(data) {
    var response = Repository.post('StructuredAssessment/CopyStructuredAssessmentStructure', data)
    return response
  },

  publishFoundationInstances(assessmentInstanceIdsCSV) {
    return Repository.post('StructuredAssessment/PublishStructuredAssessmentInstances', {
      AssessmentInstanceIdsCSV: assessmentInstanceIdsCSV
    });
  },

  uploadFile({ file, structuredAssessmentPeriodIds, fileTypeId }) {
    const formData = new FormData();

		formData.append('Files', file);
    formData.append('StructuredAssessmentPeriodIdsCSV', structuredAssessmentPeriodIds);
    formData.append('FileTypeId', fileTypeId);

    return Repository.post('StructuredAssessment/UploadStructuredAssessmentFile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  downloadFile(structuredAssessmentPeriodIds, fileTypeId) {
    var response = Repository.get('StructuredAssessment/DownloadStructuredAssessmentFile', {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      },
      params: {
        StructuredAssessmentPeriodIdsCSV: structuredAssessmentPeriodIds,
        FileTypeId: fileTypeId
      }
    });
    return response;
  },

  deleteFile(assessmentStructureIdsCSV, fileTypeId) {
    return Repository.delete('StructuredAssessment/DeleteStructuredAssessmentFile', {
      params: {
        StructuredAssessmentPeriodIdsCSV: assessmentStructureIdsCSV,
        FileTypeId: fileTypeId
      }
    });
  },


  getFileDetails(structuredAssessmentPeriodIds) {
    var response = Repository.get('StructuredAssessment/GetFileDetails', {
      params: {		
        StructuredAssessmentPeriodIdsCSV: structuredAssessmentPeriodIds
      }
    });

    return response
  },

	getQualificationsDropdownByParameters(qualifcationIds, periodIds, languageIds, subjectIds, excludeFoundationPhase = false, includeOnlyFoundationPhase = false) {
		var response = Repository.get('StructuredAssessment/GetQualificationsDropdownByParameters', {
			params: {        
				QualificationIdCSV: (qualifcationIds.length > 0) ? qualifcationIds.join(',') : null,
				PeriodIdCSV: (periodIds.length > 0) ? periodIds.join(',') : null,
				LanguageIdCSV: (languageIds.length > 0) ? languageIds.join(',') : null,
				SubjectIdCSV: (subjectIds.length > 0) ? subjectIds.join(',') : null,
				ExcludeFoundationPhase: excludeFoundationPhase,
				IncludeOnlyFoundationPhase: includeOnlyFoundationPhase
			}                
		});
		return response;        
	},

  getSubjectDropdownByParameters(qualifcationIds, periodIds, languageIds, subjectIds) {
		var response = Repository.get('StructuredAssessment/GetSubjectDropdownByParameters', {
			params: {
				QualificationIdCSV: (qualifcationIds.length > 0) ? qualifcationIds.join(',') : null,
				PeriodIdCSV: (periodIds.length > 0) ? periodIds.join(',') : null,
				LanguageIdCSV: (languageIds.length > 0) ? languageIds.join(',') : null,
				SubjectIdCSV: (subjectIds.length > 0) ? subjectIds.join(',') : null,
			}
		});
		return response;		
	},

};
