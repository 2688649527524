import axios from "axios"
import store from '@/store';
import router from "@/router";

// get config
var defaultConfig;
var xhr = new XMLHttpRequest();
xhr.open('GET', "/config.json?_=" + new Date().getTime(), false);
xhr.onload = function() {
	var status = xhr.status;
	if (status === 200) {
		defaultConfig = JSON.parse(xhr.response)
	}
};
xhr.send();

//const baseDomain = window.location.host == "localhost:8080" || window.location.host == "127.0.0.1:8080" || window.location.host == "olp.dev.optimi.dev" ? config.devBaseDomain : config.liveBaseDomain;
var baseDomain = '';
if (window.location.host == "localhost:8080" || window.location.host == "127.0.0.1:8080") {
	baseDomain = defaultConfig.localDevBaseDomain;
} else if (window.location.host == "olp.localhost.me:8080") {
	baseDomain = defaultConfig.epubBaseDomain;
} else if (window.location.host == "olp.dev.optimi.dev") {
	baseDomain = defaultConfig.devBaseDomain;
} else if (window.location.host == "olp.staging.optimi.dev") {
	baseDomain = defaultConfig.stagingBaseDomain;
} else {
	baseDomain = defaultConfig.liveBaseDomain;
}

// You can use your own logic to set your local or production domain

const baseURL = baseDomain + defaultConfig.baseUrl;

const instance = axios.create({
	baseURL,
	withCredentials: true,
});

let isRefreshing = false; // this holds any in-progress token refresh requests
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
	refreshSubscribers.push(cb);
}
  
function onRrefreshed(token) {
	refreshSubscribers.map(cb => cb(token));
	refreshSubscribers = [];
}

instance.interceptors.request.use(function(config) {
	const token = store.state.auth.token;
	if (token) {
		config.headers.Authorization = 'Bearer ' + store.state.auth.token;
	}

	if (config.onDownloadProgress) {
		const originalProgressHandler = config.onDownloadProgress;
        config.onDownloadProgress = (progressEvent) => {
			if (progressEvent.loaded && progressEvent.total) {
				const bytesLoaded = progressEvent.loaded;
				const bytesTotal = progressEvent.total;
				originalProgressHandler(bytesLoaded, bytesTotal);
			}
        };
    }

	return config;	
  }, function(err) {
	return Promise.reject(err);
  }
);
  
// create an interceptor to catch and handle 401 responses
instance.interceptors.response.use(function (response) {
	if (defaultConfig.debug) { 
		console.log("Debug response:", response)
	}

	response.loaded = true
	return response;
}, async function (error) {
	try {
		const { config, response: { status } } = error;
		const originalRequest = config;

		if (status === 400) { // bad request

			// ignore bad request response, and pass response object onto UI to handle

			return error.response;

		} else if (status === 401) {

			const retryOrigReq = new Promise((resolve, reject) => {
				subscribeTokenRefresh(token => {
					// replace the expired token and retry
					originalRequest.headers['Authorization'] = 'Bearer ' + token;
					resolve(axios(originalRequest));
				});
			});

			if (!isRefreshing) {
				isRefreshing = true;

				var { data } = await instance.post('User/RefreshToken', {});

				if (data.success === true) {
					isRefreshing = false;
					// update refreshed token in store
					store.commit("refreshToken", data.data.token);

					onRrefreshed(data.data.token);
				} else {
					refreshSubscribers = [];
					store.commit("setAuthenticated", false)
					store.commit("logout");
					router.push("/login").catch(() => {});
				}
			}

			return retryOrigReq;

		} else if (status === 404) {
			return { 
				data: { 
					success: false, 
					error: { 
						message: "Page not found." 
					}
				}
			}
		} else if (status === 403) {
			return { 
				data: { 
					success: false, 
					error: { 
						message: "Permission denied." 
					}
				}
			}

		} else {
			return { 
				data: { 
					success: false, 
					error: { 
						message: "Server Error: Please wait a monent and then try again." 
					}
				}
			}
		}
	} catch (error) {
		console.error("Network Error", error)
		return { 
			data: { 
				success: false, 
				error: { 
					message: "Server Error: Something went wrong." 
				}
			}
		}
	}
	

});

export default instance
