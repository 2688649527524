import Repository from './Repository';

export default {
    getSearchPages(searchString) {
        var response = Repository.get('Reader/GetSearchPages', {
			params: {	
				searchString: searchString
			}
		});
		return response;		
	},

	async getBook(bookId, userId, page = null, decode = false, onProgress = null) {
		var response = await Repository.get('Reader/GetBook', {
			responseType: 'arraybuffer',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/pdf'
			},			
			params: {	
				bookId: bookId,
				userId: userId,
				page: page,
				decode: decode
			},
			onDownloadProgress: onProgress
		});
		
		if (response.headers && response.headers['content-type'].includes('application/json')) {
			const decoder = new TextDecoder()
			return {success: response.success, data: JSON.parse(decoder.decode(response.data))}
		}

		return response;
	},

	getBookDataHeader(path) {
		return Repository.head(path)
	},

	setAnnotation(userId, bookId, annotations) {
		var response = Repository.post('Reader/setAnnotation', {
			userId: userId,
			bookId: bookId,
			annotations: annotations
		});
		return response;
	},

	getAnnotation(userId, bookId) {
		var response = Repository.get('Reader/GetAnnotation', {
			params: {	
				userId: userId,
				bookId: bookId
			}
		});
		return response;
	},

	searchBook(bookId, searchText) {
		var response = Repository.get('Reader/SearchBook', {
			params: {	
				bookId: bookId,
				searchText: searchText
			}
		});
		return response;
	},
	
	setLastPage(userId, bookId, lastPageOpened) {
		var response = Repository.post('Reader/SetLastPage', {
			userId: userId,
			bookId: bookId,
			lastPageOpened: lastPageOpened
		});
		return response;
	}
	
}