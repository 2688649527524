import i18n from '@/plugins/i18n'
import userUtils from "@/utils/userUtils";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
//import { faBookReader } from '@fortawesome/free-solid-svg-icons';

const MenuRepository = RepositoryFactory.get("menu");

const menuItems = {
  admin: { show: true, title: i18n.t('menu.admin'), icon: 'user', iconstyle: 'fal', link: '' },
  lessons: { show: true, title: i18n.t('menu.lessons'), icon: 'book', iconstyle: 'fal', link: '', color: '#eabf46' },
  learner: { show: true, title: i18n.t('menu.learner'), icon: 'book', iconstyle: 'fal', link: '' },
  week: { show: true, title: i18n.t('menu.week'), icon: 'book', iconstyle: 'fal', link: '' },
  calendar: { show: true, title: i18n.t('menu.calendar'), icon: 'calendar-alt', iconstyle: 'fal', link: '/calendar' },
  onlineClasses: { show: true, title: i18n.t('menu.online.classes'), icon: 'desktop', iconstyle: 'fal', link: '', color: '#1564d6' },
  assessments: { show: true, title: i18n.t('menu.assessments'), icon: 'clipboard-list-check', iconstyle: 'fal', link: '', color: '#5dc834' },
  progress: { show: true, title: i18n.t('menu.progress'), icon: 'chart-line', iconstyle: 'fal', link: '' },
  progressAndReports: { show: true, title: i18n.t('menu.progress.reports'), icon: 'chart-line', iconstyle: 'fal', link: '/progress' },
  extraResources: { show: true, title: i18n.t('menu.resources'), icon: 'folder-open', iconstyle: 'fal', link: '' },
  thisWeek: { show: true, title: "This Week", icon: 'home', iconstyle: 'fal', link: '/dashboard' },
  learnerConsole: { show: true, title: 'Learner Console', icon: 'graduation-cap', iconstyle: 'fal', link: '/learnerConsole' },
  library: { show: false, title: i18n.t('menu.library'), icon: 'book-reader', iconstyle: 'fal', link: '/library' }
}

const menuTranslations = {
  viewLibrary: "library"
}

var menu = {}

const funcs = {
  async buildMenu(role, user, courses, menuPermissions) {
    menu = {}

    if (role) {
      // Adjusting menus for supplementary courses.
      var coursesSummary = userUtils.getSupplementarySummary()

      let hideSupplementaryMenus = false;
      if(coursesSummary["hasSupplementaryCourses"] && !coursesSummary["hasFormalCourses"]) {
        hideSupplementaryMenus = true;
      }
      var isAdmin = false
      switch (role.roleId) {

        case 1: // System Administrator
          menu = await funcs.getMenuAdminPages();
          menu["show"] = true
          isAdmin = true
          break;

        case 5: // Online Classes Administrator
          menu = await funcs.getMenuOCAdminPages();
          menu["show"] = true
          isAdmin = true
          break;

        case 6: // Call Centre Administrator
          menu = await funcs.getMenuCCAdminPages();
          menu["show"] = true
          isAdmin = true
          break;

        case 10: // Assessment Administrator
          menu = await funcs.getMenuAAAdminPages();
          menu["show"] = true
          isAdmin = true
          break;

        case 13: // internal Administrator
          menu = await funcs.getMenuIAdminPages();
          menu["show"] = true
          isAdmin = true
          // menu["assessments"]["disabled"] = false
          break;
        
        case 14: // Academic Admin
          menu = await funcs.getAcademicAdminPages();
          menu["show"] = true
          isAdmin = true
          break;

        case 2: // Home Tutor
        case 9: // OS Teacher/Facilitator

        menu["show"] = true
        menu["library"] = menuItems.library
        menu["learnerConsole"] = menuItems.learnerConsole
          if(!hideSupplementaryMenus){
            menu["assessments"] = menuItems.assessments
            menu["assessments"]["children"] = [
              { title: 'Assessment Reports', icon: 'clipboard-list', iconstyle: 'fal', link: '/tutorReports' },
              { title: 'Downloads', icon: 'download', iconstyle: 'fal', link: '/assessments/downloads' },
              { title: 'Submissions', icon: 'upload', iconstyle: 'fal', link: '/assessments/submissions' }
            ];
          }

          
          
          if (userUtils.getlearnerSelected()) {

            //switchLearner(userUtils.getLearners()[], index);
            
            menu["thisWeek"] = menuItems.thisWeek
            menu["thisWeek"]["disabled"] = false
            var TutorLessons = funcs.getCoursePages('lessons', courses)
            if (TutorLessons.length > 0) {
              menu["lessons"] = menuItems.lessons
              menu["lessons"]["disabled"] = false
              menu["lessons"]["children"] = TutorLessons
            }
            if(!hideSupplementaryMenus){
              var TutorOnlineClasses = funcs.getCoursePages('onlineClasses', courses)
              if (TutorOnlineClasses.length > 0)
              {
                menu["onlineClasses"] = menuItems.onlineClasses
                menu["onlineClasses"]["disabled"] = false
                menu["onlineClasses"]["children"] = TutorOnlineClasses
              }
            
              menu["calendar"] = menuItems.calendar
              menu["calendar"]["disabled"] = false
            }
            
            menu["progress"] = menuItems.progress
            menu["progress"]["disabled"] = false
            menu["progress"]["children"] = [];
            if(coursesSummary["hasFormalCourses"]) {
              menu["progress"]["children"].push({ title: 'Formal Courses', icon: 'chart-line', iconstyle: 'fal', link: '/progress' });
            }
            if(coursesSummary["hasSupplementaryCourses"]) {
              menu["progress"]["children"].push({ title: 'Supplementary Courses', icon: 'chart-line', iconstyle: 'fal', link: '/supplementaryProgress' });
            }   

            if(!hideSupplementaryMenus){
              var TutorExtraResources = funcs.getCoursePages('extraResources', courses)
              if (TutorExtraResources.length > 0) {
                menu["extraResources"] = menuItems.extraResources
                menu["extraResources"]["disabled"] = false
                menu["extraResources"]["children"] = TutorExtraResources
              }
            }

          } else {

            menu["thisWeek"] = menuItems.thisWeek          
            menu["thisWeek"]["disabled"] = true

            menu["lessons"] = menuItems.lessons
            menu["lessons"]["disabled"] = true

            menu["onlineClasses"] = menuItems.onlineClasses
            menu["onlineClasses"]["disabled"] = true

            menu["calendar"] = menuItems.calendar
            menu["calendar"]["disabled"] = true

            menu["progress"] = menuItems.progress
            menu["progress"]["disabled"] = true

            menu["extraResources"] = menuItems.extraResources
            menu["extraResources"]["disabled"] = true

          }

          break;

        case 3: // Home Guardian
        case 8: // Online School Guardian
          menu["show"] = false
          
          menu["thisWeek"] = menuItems.thisWeek           
          menu["thisWeek"]["disabled"] = false

          var GuardianLessons = funcs.getCoursePages('lessons', courses)
          if (GuardianLessons.length > 0) {
            menu["lessons"] = menuItems.lessons
            menu["lessons"]["disabled"] = false
            menu["lessons"]["children"] = GuardianLessons
          }

          if(!hideSupplementaryMenus) {
            var GuardianAssessments = funcs.getCoursePages('assessments', courses)
            if (GuardianAssessments.length > 0) {
              menu["assessments"] = menuItems.assessments
              menu["assessments"]["disabled"] = false
              menu["assessments"]["children"] = GuardianAssessments
            }
          }

          if(!hideSupplementaryMenus) {
            var GuardianOnlineClasses = funcs.getCoursePages('onlineClasses', courses)
            if (GuardianOnlineClasses.length > 0) {
              menu["onlineClasses"] = menuItems.onlineClasses
              menu["onlineClasses"]["disabled"] = false
              menu["onlineClasses"]["children"] = GuardianOnlineClasses
            }

            menu["calendar"] = menuItems.calendar
            menu["calendar"]["disabled"] = false
            
            //menu["progressAndReports"] = menuItems.progressAndReports
            //menu["progressAndReports"]["children"] = [
            //  { title: 'Lessson Progress', link: '/progress' },
            //  { title: 'Assessment Reports', link: '/assessments/reports' }
            //];
          }

          if(!hideSupplementaryMenus) {
            menu["progressAndReports"] = menuItems.progressAndReports
            menu["progressAndReports"]["disabled"] = false
            menu["progressAndReports"]["children"] = [];
            if(coursesSummary["hasFormalCourses"]) {
              menu["progressAndReports"]["children"].push({ title: 'Formal Courses', icon: 'chart-line', iconstyle: 'fal', link: '/progress' });
            }
            if(coursesSummary["hasSupplementaryCourses"]) {
              menu["progressAndReports"]["children"].push({ title: 'Supplementary Courses', icon: 'chart-line', iconstyle: 'fal', link: '/supplementaryProgress' });
            }              
            menu["progressAndReports"]["children"].push({ title: 'Assessment Reports', icon: 'clipboard-list', iconstyle: 'fal', link: '/assessments/reports' });
          } else if(coursesSummary["hasSupplementaryCourses"]) {
            menu["progress"] = menuItems.progress
            menu["progress"]["disabled"] = false
            menu["progress"]["children"] = [];            
            menu["progress"]["children"].push({ title: 'Supplementary Courses', icon: 'chart-line', iconstyle: 'fal', link: '/supplementaryProgress' });            
          }

          if(!hideSupplementaryMenus) {
            var GuardianExtraResources = funcs.getCoursePages('extraResources', courses)
            if (GuardianExtraResources.length > 0) {
              menu["extraResources"] = menuItems.extraResources
              menu["extraResources"]["disabled"] = false
              menu["extraResources"]["children"] = GuardianExtraResources
            }
          }
          menu["library"] = menuItems.library
          break;
        case 4: // Home Learner
        case 7: //online school learner
          menu["show"] = true
          
          menu["thisWeek"] = menuItems.thisWeek             
          menu["thisWeek"]["disabled"] = false

          menu["lessons"] = menuItems.lessons
          menu["lessons"]["disabled"] = false
          menu["lessons"]["children"] = funcs.getCoursePages('lessons', courses)

          if(!hideSupplementaryMenus){
            var LearnerAssessments = funcs.getCoursePages('assessments', courses)
            if (LearnerAssessments.length > 0) {
              menu["assessments"] = menuItems.assessments
              menu["assessments"]["disabled"] = false
              menu["assessments"]["children"] = LearnerAssessments
            }
            
            var LearnerOnlineClasses = funcs.getCoursePages('onlineClasses', courses)
            if (LearnerOnlineClasses.length > 0) {
              menu["onlineClasses"] = menuItems.onlineClasses
              menu["onlineClasses"]["disabled"] = false
              menu["onlineClasses"]["children"] = LearnerOnlineClasses
            }

            menu["calendar"] = menuItems.calendar
            menu["calendar"]["disabled"] = false
          }

          var qualifications = await userUtils.getSelectedQualification();
          if (qualifications && (qualifications.qualificationId == 10 || qualifications.qualificationId == 11 || qualifications.qualificationId == 12)) { // Grade 10, 11 and 12
            /*menu["progressAndReports"] = menuItems.progressAndReports
            menu["progressAndReports"]["children"] = [
              { title: 'Lesson Progress', link: '/progress' },
              { title: 'Assessment Reports', link: '/assessments/reports' }
            ]; */
            menu["progressAndReports"] = menuItems.progressAndReports
            menu["progressAndReports"]["disabled"] = false
            menu["progressAndReports"]["children"] = [];
            if(coursesSummary["hasFormalCourses"]) {
              menu["progressAndReports"]["children"].push({ title: 'Formal Courses', icon: 'chart-line', iconstyle: 'fal', link: '/progress' });
            }
            if(coursesSummary["hasSupplementaryCourses"]) {
              menu["progressAndReports"]["children"].push({ title: 'Supplementary Courses', icon: 'chart-line', iconstyle: 'fal', link: '/supplementaryProgress' });
            }              
            menu["progressAndReports"]["children"].push({ title: 'Assessment Reports', icon: 'clipboard-list', iconstyle: 'fal', link: '/assessments/reports' });

          } else {
            /*menu["progress"] = menuItems.progress
            menu["progress"]["disabled"] = false*/
            menu["progress"] = menuItems.progress
            menu["progress"]["disabled"] = false
            menu["progress"]["children"] = [];
            if(coursesSummary["hasFormalCourses"]) {
              menu["progress"]["children"].push({ title: 'Formal Courses', icon: 'chart-line', iconstyle: 'fal', link: '/progress' });
            }
            if(coursesSummary["hasSupplementaryCourses"]) {
              menu["progress"]["children"].push({ title: 'Supplementary Courses', icon: 'chart-line', iconstyle: 'fal', link: '/supplementaryProgress' });
            }               
          }
          if(!hideSupplementaryMenus){
            var LearnerExtraResources = funcs.getCoursePages('extraResources', courses)
            if (LearnerExtraResources.length > 0) {
              menu["extraResources"] = menuItems.extraResources
              menu["extraResources"]["disabled"] = false
              menu["extraResources"]["children"] = LearnerExtraResources
            }
          }
          menu["library"] = menuItems.library
          break;
        case 12: // invigilator
          menu["library"] = menuItems.library
          menu["assessments"] = menuItems.assessments
          menu["assessments"]["children"] = [
            { title: 'Uploads and Downloads', icon: 'download', iconstyle: 'fal', link: '/assessments/downloadAndUploads' },
          ];
          menu["show"] = true
          break;
        default:
          break;
      }
    }

    if (isAdmin == false && menuPermissions) {
      for (const [key, value] of Object.entries(menuPermissions)) {
        this.showMenuItem(key, value)
      }
    }

    return menu;
  },

  getCoursePages(menuRoute, courses) {
    //console.log(menuRoute, courses)
    

    // sort courses alphabetical
    courses = courses.sort((a,b)=> {
      if (a.subject.name > b.subject.name) return 1
      else return -1
    })
    
    var pages = [];
    
    var addPage = false;
    for (let course of courses) {

      switch (menuRoute) {
        case 'assessments': addPage = course.haveAssessments || course.haveFoundationAssessments; break;
        case 'lessons': addPage = true; break;
        case 'onlineClasses': addPage = course.haveOnlineClasses && (course.courseType !== 'Supplementary'); break;
        case 'extraResources': addPage = (course.courseType !== 'Supplementary'); break;
        default:
          addPage = true;
      }

      if (addPage) {

        pages.push({
          title: course.subject.name,
          abr: course.subject.code,
          icon: course.subject.icon,
          iconstyle: course.subject.iconStyle,
          color: course.subject.colour,
          link: '/' + menuRoute + '/' + course.subject.code + '/' + course.courseId
        });        
      }
    }

    if (menuRoute == 'extraResources') {
      pages.unshift({
        title: "General documents",
        abr: "",
        icon: "folder",
        iconstyle: "fal",
        color: "#000",
        link: '/extraResources/myfolder/0'
      })
    }

    return pages;

  },

  async getMenuCourses(menuRoute, role, user) {

    var { data } = await MenuRepository.getCourses(menuRoute, role, user);
    if (data.success)
      return data.pages
    else
      console.error("Error", data.error.code + ":", data.error.message)
      return {}

  },

  async getMenuAdminPages() {
    var { data } = await MenuRepository.getAdminPages();
    return data.pages
  },

  async getMenuOCAdminPages() {
    var { data } = await MenuRepository.getOCAdminPages();
    return data.pages
  },

  async getMenuCCAdminPages() {
    var { data } = await MenuRepository.getCCAdminPages();
    return data.pages
  },

  async getMenuAAAdminPages() {
    var { data } = await MenuRepository.getAAAdminPages();
    return data.pages
  },
  
  async getMenuIAdminPages() {
    var { data } = await MenuRepository.getIAdminPages();
    return data.pages
  },

  async getAcademicAdminPages() {
    var { data } = await MenuRepository.getAcademicAdminPages();
    return data.pages
  },

  getMenuIcon(menuItem) {
    return menuItems[menuItem].icon
  },

  getMenuIconSingularType(menuItem) {
    switch (menuItem) {
      case "admin":
        return menuItems["admin"].icon
      case "lesson":
        return menuItems["lessons"].icon
      case "learner":
        return menuItems["learner"].icon
      case "week":
        return menuItems["week"].icon
      case "calendar":
        return menuItems["calendar"].icon
      case "onlineClass":
        return menuItems["onlineClasses"].icon
      case "assessment":
        return menuItems["assessments"].icon
      case "progress":
        return menuItems["progress"].icon
      case "resource":
        return menuItems["resources"].icon
    }
  },

  getMenuTitle(menuItem) {
    return menuItems[menuItem].title
  },

  getMenuColor(menuItem) {
    if (menuItems[menuItem].color)
      return menuItems[menuItem].color
    return null
  },

  unlockMenuItem(menuItem, locked) {
    if (menu.length && menu.length > 0) {
      for (var mi of menu) {
        if (mi.title.toLowerCase() == menuItem.toLowerCase()) {
          mi.disabled = locked
        }
      }
    } else {
      menu[menuItem].disabled = !menu[menuItem].disabled
    }
  },

  showMenuItem(menuItem, visible) {
    var translation = menuTranslations[menuItem]
    if (menu && Object.keys(menu).length > 0) {
      for (const [key, value] of Object.entries(menu)) {
        if (key.toLowerCase() == translation.toLowerCase()) {
          menu[key].show = visible
        }
      }
    }
  }

}

export default funcs
